import { getUserPermissions } from "./browserStorage";

export const checkRoute = (route) => {
  const permissions = getUserPermissions();
  if (route.permissions.length === 0) {
    return true;
  }
  let flag = false;
  route.permissions.forEach((permission) => {
    if (permissions.indexOf(permission) > -1) {
      flag = true;
    }
  });
  return flag;
};
