import {
  FETCH_GROUP_LIST,
  RESET_GROUP_LIST,
} from "../../constants/action.constants";

//Storage for data.
const initialState = {
  list: [],
  
};

//Controller for the storage.
const groupListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GROUP_LIST:
      return { list: action.payload }
    case RESET_GROUP_LIST:
      return initialState;
    default:
      return state;
  }
};

export default groupListReducer;
