// Dashboard
import DashboardIcon from "../assets/icons/dashboard.svg";
import InstanceIcon from "../assets/icons/instance_icon.svg";
import TouchPointIcon from "../assets/icons/touch_point_icon.svg";
import DashboardActiveIcon from "../assets/icons/dashboard_orange.svg";
import CustomerMgmtIcon from "../assets/icons/customer_mgmt.svg";

// Permissions
import { PERMISSIONS } from "../constants/index";

import PurchaseOrderManagementIcon from "../assets/icons/order_managemnt.svg";
import PurchaseOrderManagementActiveIcon from "../assets/icons/order_managemnt_orange.svg";
import { APP_ROUTES } from "../constants/appRoutes";
//Supplier Management

import React from "react";
// const Dashboard = React.lazy(() => import("../pages/containers/Dashboard"));
const InstanceList = React.lazy(() => import("../pages/containers/InstanceMGMT/InstanceList"));
const TouchPointList = React.lazy(() => import("../pages/containers/TouchPt_MGMT/TouchPt_List"));
const TouchPointView = React.lazy(() => import("../pages/containers/TouchPt_MGMT/TouchPt_View"));
const GroupList = React.lazy(() => import("../pages/containers/CustomerMGMT/Groups/GroupList"));
const AddGroup = React.lazy(() => import("../pages/containers/CustomerMGMT/Groups/AddGroup"));
const SyncTouchPoint = React.lazy(() => import("../pages/containers/TouchPt_MGMT/Sync_TouchPoint/SyncTouchPt"));
const CustomersList = React.lazy(() =>
  import("../pages/containers/CustomerMGMT/CustomersList")
);
const AddCustomers = React.lazy(() =>
  import("../pages/containers/CustomerMGMT/AddCustomer")
);
const TransactionEmailReport = React.lazy(() =>
  import("../pages/containers/Transaction_Report/TransactionEmailReport")
);
const ROUTES = [
 
  // {
  //   title: "Customer Dashboard",
  //   icon: DashboardIcon,
  //   activeIcon: DashboardActiveIcon,
  //   component: Dashboard,
  //   route: "/dashboard",
  //   sidebar: true,
  //   permissions: [],
  // },
  {
    title: "Channels",
    icon: InstanceIcon,
    activeIcon: InstanceIcon,
    component: InstanceList,
    route: "/channel-list",
    sidebar: true,
    permissions: [],
  },
  {
    title: "Touch Point",
    icon: TouchPointIcon,
    activeIcon: TouchPointIcon,
    component: TouchPointList,
    route: "/touchpoint-list",
    sidebar: true,
    permissions: [],
  },
  {
    title: "Touch Point",
    icon: TouchPointIcon,
    activeIcon: TouchPointIcon,
    component: SyncTouchPoint,
    route: "/sync-touchpoint",
    sidebar: false,
    permissions: [],
  },

  {
    title: "Touch Point",
    component: TouchPointView,
    route: APP_ROUTES.TOUCH_POINT_VIEW,
    sidebar: false,
    permissions: [],
  },

  // {
  //   title: "Customer Overview",
  //   component: TransactionEmailReport,
  //   route: "/customer/:id",
  //   sidebar: false,
  // },
  {
    title: "Customer Management",
    icon: CustomerMgmtIcon,
    activeIcon: CustomerMgmtIcon,
    component: CustomersList,
    route: "/customer-list",
    sidebar: true,
    hasSubRoutes: true,
    permissions: [],
  },
  {
    title: "Customer List",
    component: CustomersList,
    route: "/customer-list",
    sidebar: false,
    subSidebar: true,
    permissions: [],
  },
  {
    title: "Customer List",
    component: AddCustomers,
    route: "/add-customer",
    sidebar: false,
    // subSidebar: true,
    permissions: [],
  },
  {
    title: "Customer List",
    component: AddCustomers,
    route: APP_ROUTES.CUSTOMER_EDIT,
    sidebar: false,
    // subSidebar: true,
    permissions: [],
  },
  {
    title: "Groups",
    component: GroupList,
    route: "/group-list",
    sidebar: false,
    subSidebar: true,
    permissions: [],
  },
  {
    title: "Customer Management",
    component: AddGroup,
    route: "/add-group",
    sidebar: false,
    permissions: [],
  },
];

ROUTES[
  ROUTES.findIndex(
    (route) => route.title === "Customer Management" && route.sidebar
  )
]["subRoutes"] = [
  ROUTES[
    ROUTES.findIndex((route) => route.title === "Customer List" && route.subSidebar)
  ],
  ROUTES[
    ROUTES.findIndex((route) => route.title === "Groups" && route.subSidebar)
  ],
 
];

export default ROUTES;
