import { IconButton, TableCell, Tooltip } from "@material-ui/core";
import React from "react";
import EditIcon from "../../../assets/icons/edit.svg";
import DeleteIcon from "../../../assets/icons/delete.svg";

import { makeStyles } from "@material-ui/core/styles";
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

export function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export default function ActionColumn(props) {
  const { data, redirectToEdit, onDeleteAction, style } = props;

  return (
    <TableCell {...style}>
      {!data.is_default ? (
        <div className="">
          {redirectToEdit && (
            <BootstrapTooltip title="Edit" placement="bottom">
              <IconButton
                className="action-icon"
                onClick={(ev) => redirectToEdit(data)}
              >
                <img src={EditIcon} alt="Edit Icon Image" />
              </IconButton>
            </BootstrapTooltip>
          )}
          {redirectToEdit && onDeleteAction && (
            <React.Fragment></React.Fragment>
          )}
          {onDeleteAction && (
            <BootstrapTooltip title="Delete" placement="bottom">
              <IconButton
                className="action-icon ml-3"
                onClick={() => onDeleteAction(data)}
              >
                <img src={DeleteIcon} alt="delete" />
              </IconButton>
            </BootstrapTooltip>
          )}
        </div>
      ) : (
        <span>&nbsp;&nbsp;</span>
      )}
    </TableCell>
  );
}
