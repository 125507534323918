import { postAuthApi } from "../../webApis/apiResource";
import { webApiCheck } from "../../services/commonMethods";
import { LOGIN_SUCCESS, LOG_OUT } from "../../constants/action.constants";
import {
  setAccessToken,
  removeAccessToken,
  setUserDetails,
  setTokenExpiry,
  setUserPermissions,
} from "../../services/browserStorage";

export const requestLogin = (data) => {
  return async (dispatch) => {
    const response = await postAuthApi("/users/login", data);
    if (webApiCheck(response)) {
      const {
         data
      } = response;

      setTokenExpiry(data.expiresAt);
      setAccessToken(data.token);
      setUserDetails(data.data);
      // setUserPermissions(data.Role.permissions);
      dispatch(loginSuccess());
    }
  };
};

export const loginSuccess = () => {
  return {
    type: LOGIN_SUCCESS,
  };
};

export const logOut = () => {
  removeAccessToken();
  localStorage.clear();
  return {
    type: LOG_OUT,
  };
};
