// login.action
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOG_OUT = "LOG_OUT";

// toast.action
export const SUCCESS_TOAST = "SUCCESS_TOAST";
export const ERROR_TOAST = "ERROR_TOAST";
export const RESET_ERROR_TOAST = "RESET_ERROR_TOAST";
export const RESET_SUCCESS_TOAST = "RESET_SUCCESS_TOAST";

//
export const RESET_INSTANCE_LIST="RESET_INSTANCE_LIST";
export const FETCH_INSTANCE_LIST="FETCH_INSTANCE_LIST";

//
export const RESET_TOUCHPOINT_LIST="RESET_TOUCHPOINT_LIST";
export const FETCH_TOUCHPOINT_LIST="FETCH_TOUCHPOINT_LIST";

// permissions.action
export const FETCH_PERMISSIONS = "FETCH_PERMISSIONS";

// roleList.action
export const FETCH_ROLE_LIST = "FETCH_ROLE_LIST";

// roleView.action
export const FETCH_ROLE = "FETCH_ROLE";
export const RESET_ROLE = "RESET_ROLE";

// userList.action
export const FETCH_USER_LIST = "FETCH_USER_LIST";

// userView.action
export const FETCH_USER = "FETCH_USER";
export const RESET_USER = "RESET_USER";

// productList.action
export const FETCH_PRODUCT_LIST = "FETCH_PRODUCT_LIST";
export const RESET_PRODUCT_LIST = "RESET_PRODUCT_LIST";


// productView.action
export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const RESET_PRODUCT = "RESET_PRODUCT";

// productCategoryList.action
export const FETCH_PRODUCT_CATEGORY_LIST = "FETCH_PRODUCT_CATEGORY_LIST";

//userProductCategoryList.action
export const FETCH_USER_PRODUCT_CATEGORY_LIST =
  "FETCH_USER_PRODUCT_CATEGORY_LIST";

// productCategoryView.action.js
export const FETCH_PRODUCT_CATEGORY = "FETCH_PRODUCT_CATEGORY";
export const RESET_PRODUCT_CATEGORY = "RESET_PRODUCT_CATEGORY";

// productBrandList.action
export const FETCH_PRODUCT_BRAND_LIST = "FETCH_PRODUCT_BRAND_LIST";
export const RESET_PRODUCT_BRAND_LIST = "RESET_PRODUCT_BRAND_LIST";

// productBrandView.action
export const FETCH_PRODUCT_BRAND = "FETCH_PRODUCT_BRAND";
export const RESET_PRODUCT_BRAND = "RESET_PRODUCT_BRAND";

// productUnitList.action
export const FETCH_PRODUCT_UNIT_LIST = "FETCH_PRODUCT_UNIT_LIST";

// productUnitView.action
export const FETCH_PRODUCT_UNIT = "FETCH_PRODUCT_UNIT";
export const RESET_PRODUCT_UNIT = "RESET_PRODUCT_UNIT";

// productPackagingList.action
export const FETCH_PRODUCT_PACKAGING_LIST = "FETCH_PRODUCT_PACKAGING_LIST";
export const RESET_PRODUCT_PACKAGING_LIST = "RESET_PRODUCT_PACKAGING_LIST";

// productPackagingView.action
export const FETCH_PRODUCT_PACKAGING = "FETCH_PRODUCT_PACKAGING";
export const RESET_PRODUCT_PACKAGING = "RESET_PRODUCT_PACKAGING";

// shelfLifeList.action
export const FETCH_SHELF_LIFE_LIST = "FETCH_SHELF_LIFE_LIST";

// paymentTypes.action
export const FETCH_PAYMENT_TYPES = "FETCH_PAYMENT_TYPES";

// discountList.action
export const FETCH_DISCOUNT_LIST = "FETCH_DISCOUNT_LIST";

// couponView.action
export const FETCH_DISCOUNT = "FETCH_DISCOUNT";
export const RESET_DISCOUNT = "RESET_DISCOUNT";

// deliveryPersonList.action
export const FETCH_DELIVERY_PERSON_LIST = "FETCH_DELIVERY_PERSON_LIST";

// dashboard.action
export const FETCH_DASHBOARD_DATA = "FETCH_DASHBOARD_DATA";
export const FETCH_TOP_PRODUCTS = "FETCH_TOP_PRODUCTS";
export const FETCH_TOP_BRANDS = "FETCH_TOP_BRANDS";
export const FETCH_TOP_FRANCHISES = "FETCH_TOP_FRANCHISES";
export const FETCH_TOP_VM = "FETCH_TOP_VM";
export const FETCH_SHIPPING_ANALYTICS = "FETCH_SHIPPING_ANALYTICS";
export const FETCH_PURCHASE_ANALYTICS = "FETCH_PURCHASE_ANALYTICS";

// orderList.action
export const FETCH_ORDER_LIST = "FETCH_ORDER_LIST";

// orderView.action
export const FETCH_ORDER = "FETCH_ORDER";
export const RESET_ORDER = "RESET_ORDER";

// me.action
export const FETCH_ME = "FETCH_ME";
export const RESET_ME = "RESET_ME";

// cancelConfirm.action
export const SET_FORM_DIRTINESS = "SET_FORM_DIRTINESS";
export const RESET_FORM_DIRTINESS = "RESET_FORM_DIRTINESS";
export const CHECK_SHOW_MODAL = "CHECK_SHOW_MODAL";
export const RESET_SHOW_MODAL = "RESET_SHOW_MODAL";
export const RESET_CANCEL_CONFIRM = "RESET_CANCEL_CONFIRM";

// notification.action
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_COUNT = "FETCH_NOTIFICATIONS_COUNT";

// //Modal.action
// export const TRIGGER_FEEDBACK_MODAL = "TRIGGER_FEEDBACK_MODAL";
// export const TRIGGER_CONFIG_APP_PASSWORD_MODAL =
//   "TRIGGER_CONFIG_APP_PASSWORD_MODAL";

// //RefundReasionList.action
// export const FETCH_REFUND_REASON_LIST = "FETCH_REFUND_REASON_LIST";
// export const RESET_REFUND_REASON_LIST = "RESET_REFUND_REASON_LIST";
// export const FETCH_REFUNDABLE_ITEMS_LIST = "FETCH_REFUNDABLE_REFUND_LIST";
// export const RESET_REFUNDABLE_ITEMS_LIST = "RESET_REFUNDABLE_REFUND_LIST";

// //Purchase order management
// export const FETCH_PURCHASE_ORDER_LIST = "FETCH_PURCHASE_ORDER_LIST";
// export const RESET_PURCHASE_ORDER_LIST = "RESET_PURCHASE_ORDER_LIST";
// export const FETCH_PURCHASE_ORDER = "FETCH_PURCHASE_ORDER";
// export const RESET_PURCHASE_ORDER = "RESET_PURCHASE_ORDER";

//Supplier management.
export const FETCH_SUPPLIER_LIST = "FETCH_SUPPLIER_LIST";
export const RESET_SUPPLIER_LIST = "RESET_SUPPLIER_LIST";
export const FETCH_SUPPLIER = "FETCH_SUPPLIER";
export const RESET_SUPPLIER = "RESET_SUPPLIER";

//CONSUMER MANAGEMENT.
export const FETCH_CUSTOMER_LIST = "FETCH_CUSTOMER_LIST";
export const RESET_CUSTOMER_LIST = "RESET_CUSTOMER_LIST";
export const FETCH_CUSTOMER = "FETCH_CUSTOMER";
export const RESET_CUSTOMER = "RESET_CUSTOMER";
export const FETCH_GROUP_LIST = "FETCH_GROUP_LIST";
export const RESET_GROUP_LIST = "RESET_GROUP_LIST";


export const FETCH_CHANNEL_LIST = "FETCH_CHANNEL_LIST";
export const RESET_CHANNEL_LIST = "RESET_CHANNEL_LIST";
