import { getFullRouteWithQueryString } from "../services/commonMethods";

//Routes of the application.
export const APP_ROUTES = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  ORDER_VIEW: "/order/:id",
  NOTIFICATIONS: "/notifications",
  
  CHANNEL_LIST: "/channel-list",
  TOUCH_POINT_LIST: "/touchpoint-list",
  SYNC_TOUCH_POINT: "/sync-touchpoint",
  TOUCH_POINT_VIEW: "/touchpoint/:id",
  CUSTOMER_LIST: "/customer-list",
  CUSTOMER_CREATE: "/add-customer",
  CUSTOMER_EDIT: "/add-customer/:id",
  GROUP_LIST: "/group-list",
  GROUP_VIEW: "/group-view"

};

//Routes with inline query value. Example : zone/15 , warehouse/15
export const ROUTES_WITH_INLINE_PARAMS = {
  VIEW_CUSTOMER: (id) => APP_ROUTES.ORDER_VIEW.replace(":id", id),
  VIEW_TOUCH_POINT: (id) => APP_ROUTES.TOUCH_POINT_VIEW.replace(":id", id),
  VIEW_GROUP: (id) => APP_ROUTES.GROUP_VIEW.replace(":id", id),
  EDIT_CUSTOMER: (id) => APP_ROUTES.CUSTOMER_EDIT.replace(":id", id),

};
