import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import TopNavbar from "../pages/components/TopNavbar";
import AppContainer from "./AppContainer";
import ROUTES from "./routes";
import IdleTimer from "react-idle-timer";
import { logOut } from "../modules/actions/login.action";
import LogOutWarning from "../pages/components/Modals/LogOutWarning";
import {
  objectHasKeysCheck,
  RenderLazyComponent,
} from "../services/commonMethods";
import { APP_ROUTES } from "../constants/appRoutes";
import SideNavBar from "../pages/components/SideNavBar";
import { checkRoute } from "../services/routesModify";

class AuthRoutes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLogOutWarning: false,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.idealTimeout = null;
  }

  _onAction(e) {
    localStorage.setItem("userActive", "true");
    // on action method
  }

  _onActive = (e) => {
    if (this.idealTimeout) {
      clearTimeout(this.idealTimeout);
    }
  };

  _onIdle = (e) => {
    localStorage.setItem("userActive", "false");
    this.setState({ showLogOutWarning: true });
    this.idealTimeout = setTimeout(() => {
      if (localStorage.getItem("userActive") === "false") {
        localStorage.removeItem("userActive");
        this.props.logout();
      }
    }, 1000 * 60 * 5);
  };

  render() {
    return (
      <Router>
        <AppContainer>
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            events={[
              "keydown",
              "DOMMouseScroll",
              "mousedown",
              "touchstart",
              "touchmove",
              "MSPointerDown",
              "MSPointerMove",
            ]}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={1000 * 60 * 60}
          />

          <Grid container className="auth-container">
            <Grid
              item
              lg={
                // collapseSidebar ? 
                // 1 
                // :
                 2}
              className={
                // collapseSidebar ? 
                "navbar-collapse"
                //  : "navbar-container"
              }
            >
              {/* <LogoNavbar company={this.props.me.Company} /> */}
              <SideNavBar />
            </Grid>
            <Grid
              // item
              lg={
                // collapseSidebar ? 
                12
                //  : 10
                }
              className={
                // collapseSidebar
                //   ? 
                  "expand-component-container"

                  // : "component-container"
              }
            >
              <TopNavbar />
              {/* <CustomTopNavBar /> */}
              <div className="content">
                { (
                  <Switch>
                    {ROUTES.map((route, index) => {
                      return route ? (
                        <Route
                          path={route.route}
                          component={RenderLazyComponent(
                            route.component,
                            // this.updateBreadcrumbData
                          )}
                          key={index}
                          exact
                        />
                      ) : (
                        ""
                      );
                    })}
                    <Redirect from={APP_ROUTES.HOME} to={APP_ROUTES.CHANNEL_LIST} />
                  </Switch>
                )}
              </div>
            </Grid>
          </Grid>
          {this.state.showLogOutWarning ? (
            <LogOutWarning
              open={this.state.showLogOutWarning}
              onClose={() => this.setState({ showLogOutWarning: false })}
            />
          ) : (
            ""
          )}
        </AppContainer>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoutes);
