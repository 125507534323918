import { FETCH_CUSTOMER, RESET_CUSTOMER } from "../../constants/action.constants";

const initialState = {
  data: {},
};

const customerViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER:
      return { data: action.payload };
    case RESET_CUSTOMER:
      return initialState;
    default:
      return state;
  }
};

export default customerViewReducer;
