import { Card, CardContent, Grid, InputAdornment } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import LoginImage from "../../../assets/images/login_new.svg";
import "./login.scss";
import CustomInput from "../../components/Input";
import FormValidator from "../../../services/FormValidator";
import eyeVisible from "../../../assets/icons/eyeVisible.svg";
import eyeHide from "../../../assets/icons/eyeHide.svg";
import { requestLogin } from "../../../modules/actions/login.action";
import companyLogo from "../../../assets/icons/T&B_logo_1920_1080.png"
// import companyLogo from "../../../assets/icons/T_B_logo_PNG.png"

class Login extends Component {
  state = {
    isPasswordShown: false,
  };
  togglePassword = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
    };

    this.rules = {
      email: { required: true, email: true },
      password: { required: true },
    };

    this.btns = [
      {
        type: "submit",
        btnProps: {
          method: this.submit,
          text: "Submit",
          variant: "contained",
          classes: {
            root: "login-submit-btn",
            disabled: "disabled-btn disabled-login-btn",
          },
        },
      },
    ];
  }

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  submit = () => {
    // submit data
    this.props.requestLogin({
      email: this.state.email,
      password: this.state.password,
    });
  };

  render() {
    const { isPasswordShown } = this.state;
    return (
      <div className="unauth-content">
        <Grid container direction="row">
        <Grid item lg={7}>
            <img  style={{ marginRight:"2rem"}} src={companyLogo} className="login-image" alt="Login Image" />
            {/* <div style={{border:"1px solid grey", }} className="demoLogo"><span style={{position:"absolute"}}>Radium</span></div> */}
          </Grid>
          <Grid item lg={5}>
            <Card className="login-card">
              <CardContent>
                <FormValidator
                  rules={this.rules}
                  btns={this.btns}
                  data={this.state}
                >
                  <div className="card-title">Login</div>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      variant: "filled",
                    }}
                    inputProps={{
                      value: this.state.email,
                      onChange: this.handleChange,
                      placeholder: "Email",
                      name: "email",
                      className: "input-login email-login",
                      required: true,
                      type: "email",
                    }}
                  />
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      variant: "filled",
                    }}
                    inputProps={{
                      value: this.state.password,
                      onChange: this.handleChange,
                      placeholder: "Password",
                      name: "password",
                      className: "input-login",
                      required: true,
                      type: isPasswordShown ? "text" : "password",
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                          <img
                            src={isPasswordShown ? eyeHide : eyeVisible}
                            alt={
                              isPasswordShown
                                ? `Password Hide Image`
                                : `Password Visible Image`
                            }
                            className="padlock-icon"
                            onClick={this.togglePassword}
                          />
                        </InputAdornment>
                        ),
                      },
                    }}
                  />
                </FormValidator>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  requestLogin: (payload) => dispatch(requestLogin(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
